import React, {useState} from "react";
import * as events from "events";

type AppoTimes = {
    string: String;
    hour: number;
    minute: number;
    minutePercent: number;
}

type appoType = {
    day: number,
    start: AppoTimes,
    end: AppoTimes,
    label: String,
    type: String;
    height: number;
    sum: number;
};

type CalendarAppointmentTypes = {
    appo: appoType;
    hour: number;
}

const CalendarAppointment: React.FC<CalendarAppointmentTypes> = ({appo, hour}) => {
    const [hover, setHover] = useState(false);

    if (hour !== appo.start.hour) return;

    const handleMouseOver = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHover(e.type === "mouseover");
    }

    return (
        <div
            className={`calendar__container__day__content__hour__planned ${appo.type}`}
            style={{
                height: hover ? "fit-content" : `${appo.height}%`,
                maxHeight: hover ? "fit-content" : `${appo.height}%`,
                minHeight: `${appo.height}%`,
                top: `${appo.start.minutePercent}%`,
                width: hover ? "fit-content" : "100%",
                minWidth: "100%",
                zIndex: hover ? "99999" : 1,
            }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOver}
        >
            <div className={hover ? "" : "calendar__container__day__content__hour__planned__label"}>{appo.label}</div>
            <div
                className="calendar__container__day__content__hour__planned__time">
                <div>{appo.start.string}-{appo.end.string}</div>
                <div>{appo.sum}h</div>
            </div>
        </div>
    )
}

export default CalendarAppointment;