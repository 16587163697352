import React from "react";
import {formatHours} from "../Base/functions";
import CalendarAppointment from "./CalendarAppointment";

type AppoTimes = {
    string: String;
    hour: number;
    minute: number;
    minutePercent: number;
}

type appoType = {
    day: number,
    start: AppoTimes,
    end: AppoTimes,
    label: String,
    type: String;
    height: number;
    sum: number;
};

type CalendarHoursProps = {
    day: number;
    hour: number;
    appos: appoType[];
    startHour: number;
}

const CalendarHours: React.FC<CalendarHoursProps> = ({day, hour, appos, startHour}) => {
    hour = hour+startHour;
    return (
        <div className="calendar__container__day__content__hour">
            {day === 0 && formatHours(hour)}

            {appos.map((appo) => (
                day === appo.day &&
                    <CalendarAppointment
                        appo={appo}
                        hour={hour}
                    />
            ))}
            {/*{day === 1 && hour === 10 && <div className="calendar__container__day__content__hour__planned">*/}
            {/*    Planned*/}
            {/*</div>}*/}
        </div>
    )
}

export default CalendarHours;