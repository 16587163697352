import React from "react";
import CalendarHours from "./CalendarHours";

type AppoTimes = {
    string: String;
    hour: number;
    minute: number;
    minutePercent: number;
}

type appoType = {
    day: number,
    start: AppoTimes,
    end: AppoTimes,
    label: String,
    type: String;
    height: number;
    sum: number;
};

type CalendarProps = {
    day: number;
    appos: appoType[];
    startHour: number;
}

const CalendarDays: React.FC<CalendarProps> = ({day, appos, startHour}) => {
    const days = ["","Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    return (
        <div className="calendar__container__day">
            <div className="calendar__container__day__label">
                {days[day]}
            </div>
            <div className="calendar__container__day__content">
                {Array.from({length: 24-startHour}, (_, index) => index).map((hour) => (
                    <CalendarHours
                        day={day}
                        hour={hour}
                        appos={appos}
                        startHour={startHour}
                    />
                ))}
            </div>
        </div>
    )
}

export default CalendarDays;