export const formatHours = (hour:number):String => {
    let returnHour = hour.toString();
    if(hour < 10) returnHour = hour.toString().padStart(2,"0");
    return `${returnHour}:00`;
}

export const getNumbericTime = (time:String, type:number):number => {
    return Number(time.split(":")[type]);
}

export const getTimeDiff = (start:String, end:String):number => {
    const startHrs:number = timeStringToHrs(start);
    const endHrs:number = timeStringToHrs(end);
    return endHrs - startHrs;
}

export const timeStringToHrs = (timeString:String):number => {
    const hrs:number = Number(timeString.split(":")[0]);
    const minutes:number = Number(timeString.split(":")[1]);
    return hrs + minutes/60;
}