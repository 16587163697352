import CalendarDays from "./CalendarDays";
import CalendarInfos from "./CalendarInfos";
import {getNumbericTime, getTimeDiff} from "../Base/functions";
import {useEffect, useState} from "react";

type appoType = {
    day: number;
    start: String;
    end: String;
    label: String;
    type: String;
}

const CalendarContainer = () => {
    const [data,setData] = useState(null);

    useEffect(() => {
        fetch('/api/data')
            .then(res => res.json())
            .then(data => setData(data.message));
    }, []);

    console.log(data);

    const startHour = 6;

    const importAppos = [
        {
            day: 1,
            start: "07:30",
            end: "17:30",
            label: "VISCOM",
            type: "work",
        },
        {
            day: 1,
            start: "13:15",
            end: "14:45",
            label: "VO Informatik und Recht",
            type: "uni",
        },
        {
            day: 2,
            start: "07:30",
            end: "17:30",
            label: "VISCOM",
            type: "work",
        },
        {
            day: 2,
            start: "18:30",
            end: "21:45",
            label: "UE Programmierung 1",
            type: "uni",
        },
        {
            day: 3,
            start: "07:30",
            end: "14:00",
            label: "VISCOM",
            type: "work",
        },
        {
            day: 3,
            start: "15:00",
            end: "18:15",
            label: "VO Mathematische Grundlagen der Informatik 1",
            type: "uni",
        },
        {
            day: 4,
            start: "07:30",
            end: "12:00",
            label: "VISCOM",
            type: "work",
        },
        {
            day: 4,
            start: "13:15",
            end: "16:30",
            label: "VO Theoretische Informatik",
            type: "uni",
        },
        {
            day: 4,
            start: "16:45",
            end: "20:00",
            label: "VO Technische Grundlagen der Informatik",
            type: "uni",
        },
        {
            day: 5,
            start: "09:45",
            end: "11:15",
            label: "VO Programmierung 1",
            type: "uni",
        },
        {
            day: 5,
            start: "11:30",
            end: "13:00",
            label: "VO Programmierung 1",
            type: "uni",
        },
        // {
        //     day: 5,
        //     start: "08:30",
        //     end: "16:30",
        //     label: "Urlaub",
        //     type: "work",
        // },
        {
            day: 6,
            start: "16:00",
            end: "20:00",
            label: "Repeat & Study",
            type: "uni",
        },
        // {
        //     day: 7,
        //     start: "16:00",
        //     end: "20:00",
        //     label: "Repeat & Study",
        //     type: "uni",
        // },
    ];

    let appos:any[] = [];
    for(let i in importAppos) {
        appos[i] = {
            ...importAppos[i],
            start: {
                string: importAppos[i].start,
                hour: getNumbericTime(importAppos[i].start, 0),
                minute: getNumbericTime(importAppos[i].start, 1),
                minutePercent: getNumbericTime(importAppos[i].start, 1) / 0.6,
            },
            end: {
                string: importAppos[i].end,
                hour: getNumbericTime(importAppos[i].end, 0),
                minute: getNumbericTime(importAppos[i].end, 1),
                minutePercent: getNumbericTime(importAppos[i].end, 1) / 0.6,
            },
            height: ((getNumbericTime(importAppos[i].end, 0) - getNumbericTime(importAppos[i].start, 0)) * 100) + ((getNumbericTime(importAppos[i].end, 1) / 0.6) - (getNumbericTime(importAppos[i].start, 1) / 0.6)),
            sum: getTimeDiff(importAppos[i].start, importAppos[i].end),
        }
    }

    return (
        <div className="calendar">
            <div className="calendar__container">
                {Array.from({length: 8}, (_, index) => index).map((day) => (
                    <CalendarDays
                        day={day}
                        appos={appos}
                        startHour={startHour}
                    />
                ))}
            </div>
            <CalendarInfos
                appos={appos}
            />
        </div>
    )
}

export default CalendarContainer;