import React from "react";
import {config} from "../Base/config";

type appoTypes = {
    day: number;
    start: String;
    end: String;
    label: String;
    type: String;
    height: number;
    sum: number;
}

type CalendarInfosTypes = {
    appos: appoTypes[];
}

const CalendarInfos: React.FC<CalendarInfosTypes> = ({appos}) => {
    let types:Array<String> = [];
    for(let i in appos) {
        if(!types.includes(appos[i].type)) {
            types.push(appos[i].type);
        }
    }

    const sums:{[key: string]: number} = {};

    for(let type in types) {
        let sum = 0;
        for(let i in appos) {
            if(appos[i].type === types[type]) {
                sum += appos[i].sum;
            }
        }
        sums[types[type].toString()] = sum;
    }

    sums["total"] = Object.values(sums).reduce((a,b) => a + b, 0);

    return (
        <div className="calendar__infos">
            <div className="calendar__infos__logo">
                <img src={`${config.baseURL}/src/Base/Media/Images/Uni_Logo_2016_SW.png`} alt="Uni Logo"/>
            </div>
            <div className="calendar__infos__infoContainer">
                {Object.keys(sums).map((item) => (
                    <div className="calendar__infos__info">
                        <div className="flex flex--5">
                            <div className={`calendar__infos__info__color ${item}`}></div>
                            <div className="calendar__infos__info__label capitalize">{item}:</div>
                        </div>
                        <div className={item === "total" ? "calendar__infos__info__sum calendar__infos__info__sum--total" : "calendar__infos__info__sum"}>{sums[item]}h</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CalendarInfos;