import React from "react";
import CalendarContainer from "./Calendar/CalendarContainer";
import "./Base/Styles/styles.scss";

const App = () => {
    return (
        <div className="main">
            <CalendarContainer />
        </div>
    )
}

export default App;